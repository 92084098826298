
import { Options, Vue } from "vue-class-component";
import PageFooter from "@/components/Mobile/PageFooter.vue";
import CustomBtn from "@/components/Mobile/CustomBtn.vue";

@Options({
  components: {
    PageFooter,
    CustomBtn,
  },
  data() {
    return {
      btns: [
        {
          title: "苹果用户下载",
          icon: require("@/assets/mallApp/app_ios_normal.png"),
          selectedIcon: require("@/assets/mallApp/app_ios_selected.png"),
        },
        {
          title: "安卓用户下载",
          icon: require("@/assets/mallApp/app_android_normal.png"),
          selectedIcon: require("@/assets/mallApp/app_android_selected.png"),
        },
      ],
    };
  },
  methods: {
    onDownload(index: number) {
      if (index === 0) {
        location.href =
          "https://apps.apple.com/cn/app/%E5%BD%92%E5%86%9C/id1419951215";
      } else {
        location.href =
          "http://qr.hotlnk.cn/guinong";
      }
    },
  },
})
export default class Wetchat extends Vue {}
