
import { Options, Vue } from "vue-class-component";
import PageFooter from "@/components/Mobile/PageFooter.vue";

@Options({
  components: {
    PageFooter,
  },
  data() {
    return {};
  },
})
export default class WeChat extends Vue {}
