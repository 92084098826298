import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e3e867d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "btn_content",
    style: _normalizeStyle({ 'border-color': _ctx.color }),
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mouseEnter && _ctx.mouseEnter(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mouseLeave && _ctx.mouseLeave(...args)))
  }, [
    _createElementVNode("img", {
      id: "btn_img",
      src: 
        this.popup ? this.$props.config.selectedIcon : this.$props.config.icon
      
    }, null, 8, _hoisted_1),
    _createElementVNode("div", {
      id: "btn_title",
      style: _normalizeStyle({ 'color': _ctx.color })
    }, _toDisplayString(this.$props.config.title), 5)
  ], 36))
}