
import { Options, Vue } from "vue-class-component";
import CustomSlider from "@/components/Mobile/CustomSlider.vue";
import { kMobileMenusConfig } from "@/config/Mobile/MenusConfig";
import DowloadApp from "./DowloadApp.vue";
import WeChat from "./WeChat.vue";

@Options({
  components: {
    CustomSlider,
    DowloadApp,
    WeChat,
  },
  data() {
    return {
      selectedIndex: 0,
      items: kMobileMenusConfig.sc,
    };
  },
  methods: {
    handleSwitchTab(index: number) {
      this.selectedIndex = index;
    },
  },
})
export default class Page extends Vue {}
